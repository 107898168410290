import React, { useRef, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap';
import QRCode from "qrcode.react";
import html2canvas from 'html2canvas';

export default function Generator() {

  const [trackID, setTrackID] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [name, setName] = useState("");
  const [artist, setArtist] = useState("");
  const [country, setCountry] = useState("Albanien");
  const [year, setYear] = useState(2000);
  const [place, setPlace] = useState(1);

  const cardFront = useRef();

  const countryList = ["Albanien","Armenien","Aserbaidschan","Australien","Belgien","Dänemark","Deutschland","Estland","Finnland","Frankreich","Georgien","Griechenland","Irland","Island","Israel","Italien","Kroatien","Lettland","Litauen","Luxemburg","Malta","Moldau","Niederlande","Norwegen","Österreich","Polen","Portugal","Russland","San Marino","Schweden","Schweiz","Serbien","Slovenien","Spanien","Tschechien","Ukraine","Vereinigtes Königreich","Zypern",];

  const downloadFront = async () => {
    const element = cardFront.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = country + '_' + year + '_Front.png';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const downloadQR = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = country + '_' + year + "_QR.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  function ordinal_suffix_of(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

  function createTrackIDFromLink(link){

    var PREFIX = "https://open.spotify.com/intl-de/track/";

    if(isValidHttpUrl(link) && link.startsWith(PREFIX)){
      var trim = link.slice(PREFIX.length) 
      trim = trim.split('?')[0]

      setTrackID(trim)
      setIsValid(true)
    }else{
      setTrackID("Not a valid URL")
      setIsValid(false)
    }
  }

  return (
    <div className="text-center text-light pt-5">
        <h1 className='my-5'>Generator</h1>
        <Form className='px-3'>
          <FormGroup>
            <Form.Label htmlFor="linkText">Spotify Track Link:</Form.Label>
            <Form.Control type="text" id="linkText" onChange={(e) => createTrackIDFromLink(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Form.Label htmlFor="artist">Artist:</Form.Label>
            <Form.Control type="text" id="artist" onChange={(e) => setArtist(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Form.Label htmlFor="name">Name:</Form.Label>
            <Form.Control type="text" id="name" onChange={(e) => setName(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Form.Label htmlFor="country">Country:</Form.Label>
            <Form.Select id="country" onChange={(e) => setCountry(e.target.value)}>
              {countryList.map(c => (
                <option value={c}>{c}</option>
              ))}
            </Form.Select>
          </FormGroup>
          <FormGroup>
            <Form.Label htmlFor="year">Year:</Form.Label>
            <Form.Control type="text" id="year" onChange={(e) => setYear(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Form.Label htmlFor="place">Place:</Form.Label>
            <Form.Control type="number" id="place" onChange={(e) => setPlace(e.target.value)}/>
          </FormGroup>
        </Form>
        <p className='my-5'>
          <b>Track ID:</b><br />
          {trackID}
        </p>
        {isValid ? 
        <div className='d-inline-grid'>
          <a onClick={downloadQR}>
            <QRCode
              id="qrcode"
              value={trackID}
              size={600}
              level={"H"}
              includeMargin={true}
            />
          </a>
          <a onClick={downloadFront}>
            <div className="cardFront p-5" ref={cardFront}>
              <img src={require("./flags/" + country + ".png")} className='position-absolute' style={{zIndex: -1, marginTop: "-3em", marginLeft: "-18.8em", width: "600px"}}/>
              <div className="darker px-2 pt-3">
                <p style={{fontSize: "35px"}} className='mb-0'>{country}</p>
                <h1 style={{fontSize: "140px", fontWeight: "900", marginBottom: 0}}>{year}</h1>
                <p style={{fontSize: "50px", fontWeight: "900", marginTop: 0}}>{place}. Platz</p>
                <p style={{fontSize: "35px"}}><b>{artist}</b> - {name}</p>
              </div>
            </div>
          </a>
        </div>
        : null}
    </div>
  )
}
