import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./Login";
import { useEffect, useState } from "react";
import Play from "./Play";
import { setClientToken } from "./Spotify";
import Generator from "./Generator";

function App() {
  
  const [token, setToken] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const hash = window.location.hash;
    window.location.hash = "";
    if (!token && hash) {
      const _token = hash.split("&")[0].split("=")[1];
      window.localStorage.setItem("token", _token);
      setToken(_token);
      setClientToken(_token);
    } else {
      setToken(token);
      setClientToken(token);
    }
  }, []);

  return !token ? (
    <Login />
  ) : (
    <Router>
        <Routes>
          <Route exact path="/" element={<Play />} />
          <Route exact path="/generator" element={<Generator />} />
        </Routes>
    </Router>
  );
}

export default App;
