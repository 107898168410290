import React, { useEffect, useRef, useState } from 'react'
import QrScanner from 'qr-scanner'
import { Alert, Modal } from 'react-bootstrap';
import apiClient from './Spotify';


export default function Scan({setSongURI, changeSong}) {

  const videoElementRef = useRef(null);
  const [message, setMessage] = useState('');

  /*function isAlphanumeric(str) {
    return /^[a-zA-Z0-9]+$/.test(str);
  }*/

  useEffect(() => {
    const video = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        var res = result.data;

        apiClient.get("tracks/" + res).then(() => {
          changeSong(res);
        }).catch(() => {
          setMessage("This is not a valid Spotify Track ID");
        })

        /*if(res.length === 22 && isAlphanumeric(res)){
          changeSong(result.data);
        }else{
          setMessage("This is no Spotify Track ID");
        }*/

        
        
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();
    //console.log('start');

    return () => {
      //console.log(qrScanner);
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  // const qrScanner = new QrScanner(videoElement, (result) =>
  //   console.log('decoded qr code:', result)
  // );

  return (
    <>
        <Modal.Header closeButton >
            <Modal.Title className='text-dark'>Scan Song</Modal.Title>
        </Modal.Header>
        <Modal.Body className="videoWrapper mb-0">
          <video className="qrVideo" ref={videoElementRef} />
        </Modal.Body>
        {
            message !== "" ? 
            <>
              <Alert variant="danger" className='my-0 mx-2'>
                {message}
              </Alert>
              <br/>
            </>
            : null
          }
    </>
  );
}
