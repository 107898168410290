import axios from "axios";

const authEndpoint = "https://accounts.spotify.com/authorize?"
const clientID = "450007765efb41f99dcca51af56f50a6"
//const redirectURI = "http://localhost:3000"
const redirectURI = "https://songtition.alpakagamestudios.de"
const scopes = ["streaming","user-read-email","user-read-private","user-read-playback-state","user-modify-playback-state"]

export const loginEndpoint = `${authEndpoint}client_id=${clientID}&redirect_uri=${redirectURI}&scope=${scopes.join(
    "%20"
  )}&response_type=token&show_dialog=true`;
  
  const apiClient = axios.create({
    baseURL: "https://api.spotify.com/v1/",
  });
  
  export const setClientToken = (token) => {
    apiClient.interceptors.request.use(async function (config) {
      config.headers.Authorization = "Bearer " + token;
      return config;
    });
  };
  
  export default apiClient;