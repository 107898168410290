import React, { useEffect, useState } from 'react'
import apiClient from './Spotify'
import SpotifyPlayer from 'react-spotify-web-playback';
import { Button, Modal } from 'react-bootstrap';
import Scan from './Scan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function Play() {
    const [image, setImage] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png")
    const [name, setName] = useState("User")

    const [play, setPlay] = useState(false);
    const [songURI, setSongURI] = useState("");
    const [isOpen, setOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        apiClient.get("me").then(response => {
            //console.log(response)
            setImage(response.data.images[0].url)
            setName(response.data.display_name)
        }).catch(error => {
            //console.log(error)
            window.localStorage.setItem("token", "");
            navigate("/")
            window.location.reload();
        })
    }, [isOpen])

    function playResume(){
        setPlay(!play);
    }

    function changeSong(songURI){
        closeModal();
        setPlay(false);
        setSongURI(songURI);
        setTimeout(function() {
            setPlay(true);
          }, 1000);
    }

    

    const openHello = (event) => {
        setOpen(!isOpen);
        //(isOpen);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const qrScanner = isOpen ? <Scan changeSong={changeSong} close={closeModal} /> : null;

    return (
        <div className='text-center text-light'>
            <img className="rounded mt-5 mb-2" src={image}/>
            <p>Logged in as "{name}"</p>
            <a disabled={songURI === ""} className='px-5 link-success' style={{fontSize: "200px", cursor: "pointer"}} onClick={playResume}>{play ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}</a><br />

            <Button onClick={openHello} variant='success' className='mt-5'><FontAwesomeIcon icon={faQrcode} /> Scan Song</Button>
            <Modal className="text-light" show={isOpen} onHide={closeModal}>
                <div>{qrScanner}</div>
            </Modal>

            <div className="mt-1" style={{opacity: 0}}>
                <div style={{backgroundColor: "black", width: "100%", height: "500px;", position: "absolute", zIndex: 5, padding: "90px"}}></div>
                <SpotifyPlayer
                    token={window.localStorage.getItem("token")}
                    uris={['spotify:track:' + songURI]}
                    play={play}
                />;
            </div>
            
        </div>
    )
}
