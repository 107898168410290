import React from 'react'
import { loginEndpoint } from './Spotify'
import { Button } from 'react-bootstrap'

export default function Login() {

  return (
    <div className="text-center text-light pt-5">
        <h1 className='my-5'>SONGTITION</h1>
        <Button href={loginEndpoint} variant="success" size="lg" className='mt-5'>Connect with Spotify</Button>
    </div>
  )
}
